var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  }, [_c('path', {
    attrs: {
      "d": "M60 30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30Z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M15 29.375c0-6.776 0-10.165 2.105-12.27C19.21 15 22.6 15 29.375 15h1.25c6.776 0 10.165 0 12.27 2.105C45 19.21 45 22.6 45 29.375v1.25c0 6.776 0 10.165-2.105 12.27C40.79 45 37.4 45 30.625 45h-1.25c-6.776 0-10.165 0-12.27-2.105C15 40.79 15 37.4 15 30.625v-1.25Z",
      "fill": "#fff"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M23.126 24.375h-2.188c-.625 0-.75.294-.75.619 0 .58.741 3.453 3.453 7.253 1.808 2.596 4.356 4.003 6.674 4.003 1.39 0 1.563-.313 1.563-.85v-1.962c0-.626.132-.75.572-.75.324 0 .88.162 2.179 1.413 1.483 1.484 1.728 2.149 2.562 2.149h2.188c.626 0 .938-.313.758-.93-.197-.614-.906-1.506-1.846-2.563-.51-.602-1.275-1.251-1.506-1.576-.325-.417-.232-.602 0-.973 0 0 2.665-3.754 2.944-5.029.139-.463 0-.804-.662-.804h-2.188c-.556 0-.813.294-.952.619 0 0-1.113 2.711-2.689 4.472-.51.51-.742.672-1.02.672-.139 0-.34-.162-.34-.625v-4.334c0-.556-.162-.804-.625-.804h-3.439c-.347 0-.556.258-.556.503 0 .527.788.649.869 2.132v3.221c0 .706-.128.835-.406.835-.742 0-2.546-2.724-3.616-5.84-.21-.606-.42-.851-.98-.851Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }